
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { mask } from "vue-the-mask";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "add-standard-categories-modal",
  directives: { mask },
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
  },

  components: {},
  // created () {
  //       const field = document.querySelector("input[name=test]").value
  //       console.log(field)
  // }

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductStandardMappingModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const loadingStandard = ref<boolean>(false);
    const router = useRouter();
    //var formData = ref({})
    const store = useStore();
    var mapping_list: any[] = [];
    const std = ref([]);
    //const inputValue = new Modal(document.getElementById("cat_id"));

    const formData = ref({
      standard_select: "",
      category_id: "",
    });

    const getStandardDetailsbyId = async (standard_id) => {
      var values = { standard_id: standard_id, page: 1, records_per_page: 1 };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values)
        .then(({ data }) => {
          std.value = data;
          console.log("standard api res");
          console.log(std.value);
        });
    };

    //  const getStandardData = async () => {

    //     loadingStandard.value = true;

    //     const db_data = {"standard_id" : formData.value.id, "page" : 1, "records_per_page" : 1 }

    //     await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, db_data).then(({ data }) => {

    //       if(data){

    //          formData.value. standard_select = data.standard_master_id
    //          formData.value.comm = data['comm_no']
    //          formData.value.title = data['standard_title']
    //          formData.value.lic = data.no_of_lic

    //         loadingStandard.value = false;

    //       }else{
    //          formData.value. standard_select = ""
    //          formData.value.comm = ""
    //          formData.value.title = ""
    //          formData.value.lic = ""

    //         Swal.fire({
    //           text: "Sorry, It's Invalid Standard No.!",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });

    //         loadingStandard.value = false;

    //       }

    //     })
    //     .catch(({ response }) => {

    //       Swal.fire({
    //           text: "Sorry, It's Invalid Standard No.!",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });

    //         loadingStandard.value = false;
    //     });
    // }

    const setLinkData = async (standard_data) => {
      var user = JSON.parse(JwtService.getToken());
      for (var i = 0; i < standard_data.standard_select.length; i++) {
        // call standard api to fetch standard master id
        //getStandardDetailsbyId(standard_data.standard_select[i])
        console.log("standard_data");
        console.log(standard_data);

        var category_id = (
          document.querySelector("input[name=cat_id]") as HTMLInputElement
        ).value;
        var values = {
          standard_id: standard_data.standard_select[i],
          page: 1,
          records_per_page: 1,
        };
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values)
          .then(({ data }) => {
            std.value = data;
          });

        const db_data = {
          category_id: category_id,
          standard_id: standard_data.standard_select[i],
          standard_master_id: std.value["standard_master_id"],
          active: 1,
          created_user_id: user.user_id,
        };

        await store
          .dispatch(Actions.CUST_LINK_PRODUCT_STANDARD_CAT, db_data)
          .then(({ data }) => {
            if (data.category_id) {
              setTimeout(() => {
                //loading.value = false;
                Swal.fire({
                  text: "Product Standard Mapping has been successfully linked.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addProductStandardMappingModalRef.value);
                  router.go(0);
                });
              }, 2000);
            } else {
              ///loading.value = false;

              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              return false;
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    onMounted(async () => {
      //await setFormData(props.data);
      await setStandardData("");
    });

    const rules = ref({
      standard_select: [
        {
          required: true,
          message: "Standard Number is required",
          trigger: "change",
        },
      ],
    });

    const standard = ref([]);
    const setStandardData = async (data) => {
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_GRADE_STANDARD, values)
          .then(({ data }) => {
            standard.value = data.page_data;
            console.log(standard.value);
            loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    //     const setProductStandardData = async (data) => {
    //       //var user = JSON.parse(JwtService.getToken());
    //       const db_data = {
    //             "standard_id" : data.id,
    //             "standard_no": data.num,
    //             "standard_master_id": data.standard_select,
    //             "comm_no": data.comm,
    //             "standard_title": data.title,
    //             "no_of_lic": data.lic,
    //             "active": false
    //         }

    //         await store.dispatch(Actions.CUST_UPDATE_PRODUCT_STANDARD, db_data).then(({ data }) => {
    //         console.log(data);

    //         if (data)
    //         {
    //             setTimeout(() => {
    //               loading.value = false;
    // //
    //               Swal.fire({
    //                 text: "Product Standard has been successfully edited.",
    //                 icon: "success",
    //                 buttonsStyling: false,
    //                 confirmButtonText: "Ok, got it!",
    //                 customClass: {
    //                   confirmButton: "btn btn-primary",
    //                 },
    //               }).then(() => {
    //                 hideModal(editProductStandardModalRef.value);
    //                 router.go(0)
    //               });
    //             }, 2000)

    //           } else {

    //             loading.value = false;

    //             Swal.fire({
    //               text: "Sorry, looks like there are some errors detected, please try again.",
    //               icon: "error",
    //               buttonsStyling: false,
    //               confirmButtonText: "Ok, got it!",
    //               customClass: {
    //                 confirmButton: "btn btn-primary",
    //               },
    //             })
    //             return false;
    //           }
    //         })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //     };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setStandardData,
      standard,
      std,
      getStandardDetailsbyId,
      //getStandardData,
      addProductStandardMappingModalRef,
    };
  },
});
