
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  onUpdated,
  HtmlHTMLAttributes,
} from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductCategoriesModal from "@/components/modals/forms/AddProductCategoriesModal.vue";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import AddStandardCategoriesModal from "@/components/modals/forms/AddStandardCategoriesModal.vue";
import AddCategoryAttribute from "@/components/modals/forms/AddCategoryAttribute.vue";
import AddAttributeRangeModal from "@/components/modals/forms/AddAttributeRangeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
//import UploadCategoryImage from "@/components/modals/forms/UploadCategoryImage.vue";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddProductCategoriesModal,
    EditProductCategoriesModal,
    AddStandardCategoriesModal,
    //AddAttributeRangeModal,
    //AddCategoryAttribute,
    //UploadCategoryImage,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    interface Categories {
      category_id: number;
      form_name: string;
      //display_order: string;
      user: {
        avatar: string;
        image_name: string;
      };
      name: string;
      active: {
        label: string;
        color: string;
      };
      category_form_name: string;
      shape_name: string;
      parent_category_name: string;
      category_stage_name: string;
      category_short_name: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
      page_select: 1
    };

    // const image_modal = (category_id) => {
    //   const modal = new Modal(document.getElementById("kt_image_modal_add_group"));
    //   modal.show();
    //   (document.querySelector('#cat_id') as HTMLInputElement).value = category_id;
    // }

    const standard_modal = (category_id) => {
      const modal = new Modal(document.getElementById("kt_modal_standard"));
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    const attr_modal = (category_id) => {
      const modal = new Modal(document.getElementById("kt_modal_attribute"));
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    const attr_range_modal = (category_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_range_attribute")
      );
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      paginationData.value['page_select'] = 1;
      getCategoriesList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCategoriesList(searchTearm.value);
       if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    };

    var tableData = ref<Array<Categories>>([]);

    const getCategoriesList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          standard_id: 0,
          search_term: data,
          page: parseInt(paginationData.value["activePage"]) || parseInt(paginationData.value['page_select']),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_GET_CATEGORY_NEW_DB_LIST, values)
          .then(({ data }) => {
            console.log(data);

            tableData.value = [];
            if(data != 0){
            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<Categories>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                category_id: data.result_list[j]["category_id"],
                user: {
                  image_name: data.result_list[j]["image_name"],
                  avatar: data.result_list[j]["image_path"],
                },
                form_name: data.result_list[j]["form_name"],
               // display_order: data.result_list[j]["display_order"],
                comp_type: data.result_list[j]["company_business_type_name"],
                status: {
                  label: status_label,
                  color: status_color,
                },
                active: {
                  label: active_label,
                  color: active_color,
                },
                name: data.result_list[j]["category_name"],
                category_form_name: data.result_list[j]["category_form"],
                shape_name: data.result_list[j]["shape_name"],
                parent_category_name: data.result_list[j]["category_name_parent"],
                category_stage_name: data.result_list[j]["category_stage"],
                category_short_name: data.result_list[j]["category_short_name"],
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
            }
            if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationData.value['total'] = 0;        
          paginationData.value['start'] = 0
          paginationData.value['end'] = 0
          paginationData.value['activePage'] = 1;
          paginationData.value['totPage']  = 1
          paginationData.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          // paginationData.value['page_select'] = 0
          //endpagination
          }
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<Categories>>([]);

    onMounted(async () => {
      await getCategoriesList(searchTearm.value);
      setCurrentPageBreadcrumbs("Products Categories", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      console.log(tableData.value.length);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].category_id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      refreshData();
      getCategoriesList(search.value);
      console.log(search.value);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCategoriesList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      //image_modal,
      standard_modal,
      attr_range_modal,
      attr_modal,
    };
  },
});
