
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {
    AddTagsModal,
  },
  setup() {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const treeRef = ref<null | HTMLFormElement>(null);
    const addProductCategoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const uom_data = ref([]);
    const shape = ref([]);
    const level = ref([
      {
        id: 0,
        name: "Level 1",
      },
      {
        id: 1,
        name: "Level 2",
      },
      {
        id: 2,
        name: "Level 3",
      },
      {
        id: 3,
        name: "Level 4",
      },
    ]);
    const selection = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);
    const newTreeValue = ref<any>([]);
    const newTreeNode = ref<any>({});
    const categoryTree = ref([]);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });

    onMounted(async () => {
      //const db_data = {}
      await setShapedata();
      await getUOM();
      // await setTagData("");
      // await setIndustrydata(industry.value);
      await setFormdata();
      // await setStagedata(stage.value);
      // await setLengthdata(length.value);
      // await setParentData("");
      await getCategoryTree();
      //await setHsnData("");
    });

    const UploadImage = async (category_id) => {
      debugger;
      const db_data = {
        // category_id: category_id,
        file_name: category_id + ".png",
        // file_name: category_id + "." + rawImg.value.file_name.split(".")[1],
        file_data: rawImg.value.file_data,
        // user_id: 1,
      };

      await store
        .dispatch(Actions.CUST_ADD_CAT_IMAGE, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Category has been successfully & Uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductCategoryModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    let rawImg = ref();
    function encodeImagetoBase64L(element) {
      debugger;
      const image = element.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = (event) => {
        var image_encode = String(reader.result);
        if (image_encode) {
          var image_slice = image_encode.split(",")[1];
          rawImg.value = {
            file_name: element.target.files[0].name,
            file_data: image_slice,
          };
          console.log("helllo");
          console.log(image_slice);
        } else {
          rawImg.value = {
            file_name: element.target.files[0].name,
            file_data: reader.result,
          };
        }
      };
    }

    const setShapedata = async () => {
      // const db_data = {};
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_ADD_CATEGORY_DP)
          .then(({ body }) => {
            shape.value = body.shape_list;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const industry = ref([]);
    const sub = ref([]);
    const setIndustrydata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_INDUSTRY_CATEGORY, param)
          .then(({ body }) => {
            industry.value = body.industry_types_pc;
            //loading.value = false;
            console.log("sub.value");
            console.log(sub.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const form = ref([]);
    const setFormdata = async () => {
      //const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_ADD_CATEGORY_DP)
          .then(({ body }) => {
            form.value = body.form_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const length = ref([]);
    const setLengthdata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_Length_CATEGORY, param)
          .then(({ body }) => {
            length.value = body.length;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const parent = ref([]);
    const setParentData = async (data) => {
      // loading.value = true;
      try {
        var values = { search_term: data };
        if (values.search_term) {
          console.log("values");
          console.log(values);
          await store
            .dispatch(Actions.CUST_GET_CATEGORY, values)
            .then(({ data }) => {
              parent.value = data.page_data;
              loading.value = false;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const tag = ref([]);
    const setTagData = async (data) => {
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_TAGLIST, values)
          .then(({ data }) => {
            tag.value = data.page_data;
            //loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const addtag: any[] = [];
    const addTags = async (data) => {
      // for (let i=0; i<data.length; i++){
      //   const db_data = {
      //     "tag_name": data[i],
      //     "created_user_id": "1",
      //     }
      //     try {
      //       await axios.post(
      //         process.env.VUE_APP_CUSTOME_APP_API_URL+"product/create_tag",
      //         db_data,
      //       {
      //         headers: {
      //           "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
      //         },
      //       }
      //     ).then(({ data }) => {
      //       addtag.push(data.is_success)
      //       console.log(db_data);
      //       console.log(data);
      //     })
      //     .catch(({ response }) => {
      //       console.log(response);
      //     });
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    };

    const hsn = ref([]);
    const setHsnData = async (data) => {
      const db_data = {
        search_term: "",
      };
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn.value = data;
            console.log("HSN VALUE");
            console.log(hsn.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };
    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const getHsnData = async () => {
      // loadingHsn.value = true;
      // const db_data = { "search_term" : formData.value.hsn_select }
      // console.log("AAAAAAAAAAAAA")
      // console.log(db_data)
      // await store.dispatch(Actions.CUST_HSN_CATEGORY, db_data).then(({ data }) => {
      //     console.log(data);
      //   if(data){
      //     formData.value.hsn_code = data['hsn_code']
      //     formData.value.hsn_description = data['hsn_description']
      //     loadingHsn.value = false;
      //   }else{
      //     formData.value.hsn_description = ""
      //     Swal.fire({
      //       text: "Sorry, It's Invalid HSN!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     loadingHsn.value = false;
      //   }
      // })
      // .catch(({ response }) => {
      //   Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     loadingHsn.value = false;
      // });
    };
    const formData = ref({
      name: "",
      short_name: "",
      description: "",
      parent_select: "",
      active_select: 0,
      inherit_parent_attr_select: 0,
      display_app_landing_select: 0,
      display_research_select: 0,
      display_quick_buy_select: 0,
      display_quick_sell_select: 0,
      uom_select: "",
      display_order: "",
      stage_select: "",
      length_select: "",
      industry_select: "",
      tag_select: "",
      hsn_select: "",
      hsn_code: "",
      hsn_description: "",
      active: "",
      form_select: "",
      shape_select: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],
      short_name: [
        {
          required: true,
          message: "Category Short Name is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],

      // description: [
      //   {
      //     required: true,
      //     message: "Description is required",
      //     trigger: "change",
      //   },
      // ],
      // hsn_select: [
      //   {
      //     required: true,
      //     message: "Hsn is required",
      //     trigger: "change",
      //   },
      // ],

      parent_select: [
        {
          required: true,
          message: "Parent is required",
          trigger: "change",
        },
      ],
      active_select: [
        {
          required: true,
          message: "Active Select is required",
          trigger: "change",
        },
      ],
      display_app_landing_select: [
        {
          required: true,
          message: "Display App Landing is required",
          trigger: "change",
        },
      ],
      display_research_select: [
        {
          required: true,
          message: "Display Research is required",
          trigger: "change",
        },
      ],
      display_quick_buy_select: [
        {
          required: true,
          message: "Display Quick Buy is required",
          trigger: "change",
        },
      ],
      display_quick_sell_select: [
        {
          required: true,
          message: "Display Quick Sell is required",
          trigger: "change",
        },
      ],
      display_order: [
        {
          required: true,
          message: "Display Order is required",
          trigger: "change",
          min: 1,
          max: 3,
        },
      ],
      stage_select: [
        {
          required: true,
          message: "Stage is required",
          trigger: "change",
        },
      ],
      length_select: [
        {
          required: true,
          message: "Length is required",
          trigger: "change",
        },
      ],
      industry_select: [
        {
          required: true,
          message: "Industry type is required",
          trigger: "click",
        },
      ],
      // uom_select: [
      //   {
      //     required: true,
      //     message: "UOM is required",
      //     trigger: "change",
      //   },
      // ],
      inherit_parent_attr_select: [
        {
          required: true,
          message: "Inherit parent attributes required",
          trigger: "change",
        },
      ],
      tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",
        },
      ],
    });

    const setCategoriesData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      // var tag_id_list: any[] = [];
      // var tag_text_list: any[] = [];
      // for (let j = 0; j < data.tag_select.length; j++) {
      //   console.log(data.tag_select[j]);
      //   console.log(typeof data.tag_select[j]);
      //   console.log(typeof data.tag_select[j] == "string");
      //   if (typeof data.tag_select[j] == "string") {
      //     tag_text_list.push(data.tag_select[j]);
      //     console.log(tag_text_list);
      //   } else if (typeof data.tag_select[j] == "number") {
      //     tag_id_list.push(data.tag_select[j]);
      //   }
      // }

      // for (let i = 0; i < tag_text_list.length; i++) {
      //   const db_data = {
      //     tag_name: tag_text_list[i],
      //     created_user_id: "1",
      //   };
      //   await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
      //     addtag.push(data.tag_id);
      //   });
      // }

      // const merge = tag_id_list.concat(addtag);

      // let parentcat = 0;
      // if (!data.parent_select) {
      //   parentcat = 0;
      // } else {
      //   parentcat = data.parent_select;
      // }
      // if (!rawImg.value) {
      //   Swal.fire({
      //     text: "Category Image is Required.",
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Ok, got it!",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   });
      // }
      const db_data = {
        category_name: data.name,
        shape_id: data.shape_select,
        form_id: data.form_select,
        category_short_name: data.short_name,
        category_description: data.description,
        //hsn_code: data.hsn_select ? data.hsn_select : 0,
        category_id_parent: data.parent_select,
        active: data.active_select,
        display_order: 0,
        uom_id: data.uom_select,
        inherit_attributes: data.inherit_parent_attr_select,
        display_app_landing: data.display_app_landing_select,
        display_research: data.display_research_select,
        display_quick_buy_landing: data.display_quick_buy_select,
        display_quick_sell_landing: data.display_quick_sell_select,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_CATEGORY, db_data)
        .then(({ data }) => {
          debugger;
          if (data.is_success) {
            if (rawImg.value) {
              UploadImage(data.category_id);
            }
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Category has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductCategoryModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getUOM() {
      await store
        .dispatch(Actions.CUST_ADD_UOM_LIST)
        .then(({ data }) => {
          debugger;
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCategoriesData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };
    interface Tree {
      category_name: string;
      category_id: string;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      if (checked) {
        treeRef.value?.setCheckedKeys([data.category_id], true);
        newTreeValue.value = [data.category_id];
        formData.value["parent_select"] = data.category_id;
        newTreeNode.value = data;
      } else {
        if (data.category_id === newTreeValue.value[0]) {
          treeRef.value?.setCheckedKeys([], false);
          newTreeValue.value = [];
          formData.value["parent_select"] = "";
          newTreeNode.value = data;
        } else {
          treeRef.value?.setCheckedKeys(newTreeValue.value, false);
          formData.value["parent_select"] = newTreeValue.value[0]
            ? newTreeValue.value[0]
            : "";
        }
      }
      console.log(data, checked, indeterminate);
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addProductCategoryModalRef,
      setFormdata,
      setLengthdata,
      setShapedata,
      setIndustrydata,
      setParentData,
      setHsnData,
      setTagData,
      getHsnData,
      addTags,
      encodeImagetoBase64L,
      active_list_data,
      form,
      length,
      rawImg,
      addtag,
      shape,
      level,
      tag,
      industry,
      parent,
      hsn,
      sub,
      categoryTree,
      getCategoryTree,
      treeProps,
      handleCheckChange,
      treeRef,
      newTreeValue,
      newTreeNode,
      selection,
      uom_data,
      // treeValue,
      // options,
    };
  },
});
